import { api } from "../apiFactory";
import store from "@/store";

const getCurrentVenueId = () => store.getters["employees/currentVenueId"];

export const tagsApi = {
  getAllVenueTags: () => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`venue/tag/${currentVenueId}`);
  },
  editTag: (id, data) => api.patch(`tag/${id}`, data),
  createTag: (data) => {
    return api.post(`tag`, data);
  },
  deleteTag: (id) => api.delete(`tag/${id}`),
};

export default tagsApi;
