export default [
  {
    path: "/settings",
    name: "VenueSettings",
    component: () => import("../../views/venueSettings/home.vue"),
    meta: { requiresAuth: true, requiresVenue: true },
    children: [
      {
        path: "basic",
        name: "BasicSettings",
        component: () =>
          import("../../views/venueSettings/venue/BasicSettings.vue"),
        children: [
          {
            path: "",
            name: "VenueBasicData",
            component: () =>
              import(
                "../../views/venueSettings/venue/basic/BasicVenueSettings.vue"
              ),
          },
          {
            path: "billing",
            name: "VenueBilling",
            component: () =>
              import(
                "../../views/venueSettings/venue/billing/VenueBilling.vue"
              ),
          },
          {
            path: "hardware",
            name: "HardwareAutomation",
            component: () =>
              import(
                "../../views/venueSettings/venue/hardware/HardwareAutomation.vue"
              ),
          },
        ],
      },
      {
        path: "tags",
        name: "TagsSettings",
        component: () =>
          import("../../views/venueSettings/tags/TagsSettings.vue"),
      },
      {
        path: "sports-facilities",
        name: "SportsFacilitiesSettings",
        component: () =>
          import(
            "../../views/venueSettings/sportObjects/SportsFacilitiesSettings.vue"
          ),
      },
      {
        path: "seasons",
        name: "SeasonsSettings",
        component: () =>
          import("../../views/venueSettings/seasons/SeasonsSettings.vue"),
      },
      {
        path: "pricing",
        name: "PricingSettings",
        component: () =>
          import("../../views/venueSettings/pricing/PricingSettings.vue"),
      },
      {
        path: "pricing/:id",
        name: "SinglePriceList",
        component: () =>
          import("../../views/venueSettings/pricing/SinglePriceList.vue"),
        props: true,
      },
      {
        path: "pricing/:id/edit/:priceListId",
        name: "SinglePriceEdit",
        component: () =>
          import("../../views/venueSettings/pricing/SinglePriceEdit.vue"),
        props: true,
      },
      {
        path: "carnets",
        name: "CarnetSettings",
        component: () =>
          import("../../views/venueSettings/carnets/CarnetSettings.vue"),
      },
      {
        path: "carnets/:id/edit",
        name: "EditCarnet",
        component: () =>
          import("../../views/venueSettings/carnets/CarnetSettings.vue"),
        props: true,
      },
      {
        path: "diagnostics",
        name: "DiagnosticsSettings",
        component: () =>
          import(
            "../../views/venueSettings/diagnostics/DiagnosticsSettings.vue"
          ),
      },
    ],
  },
];
