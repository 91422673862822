<template>
  <div>
    <!-- Główny pasek -->
    <div class="bg-white">
      <div
        class="h-12 px-4 flex items-center justify-between border-b border-light-grey"
      >
        <img
          src="/images/Playmore-Logo-Default-version-SVG.svg"
          alt="Playmore Logo"
          class="h-5"
        />
        <button
          @click="toggleMenu"
          class="p-2 text-cod-grey hover:text-dynamic-orange focus:outline-none"
        >
          <component
            :is="isMenuOpen ? icons.XMarkIcon : icons.Bars3Icon"
            class="w-5 h-5"
          />
        </button>
      </div>

      <!-- Rozwijane menu -->
      <div
        class="bg-white overflow-hidden transition-all duration-300"
        :style="{ height: isMenuOpen ? menuHeight : '0' }"
      >
        <!-- Wybór ośrodka -->
        <div class="p-3 border-b border-light-grey">
          <select
            v-model="selectedVenue"
            @change="selectVenue"
            class="w-full p-2 text-sm border border-light-grey rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-dynamic-orange focus:border-dynamic-orange"
          >
            <option value="" disabled>Wybierz ośrodek</option>
            <option
              v-for="venue in availableVenues"
              :key="venue.id"
              :value="venue.id"
            >
              {{ venue.name }}
            </option>
          </select>
        </div>

        <!-- Menu nawigacji -->
        <nav class="py-1">
          <router-link
            v-for="(item, index) in menuItems"
            :key="index"
            :to="item.path"
            class="flex items-center px-4 py-2 text-base font-medium transition-colors duration-150"
            :class="[
              isActive(item.path)
                ? 'bg-dynamic-orange text-white'
                : 'text-cod-grey hover:bg-gray-50',
            ]"
            @click="closeMenu"
          >
            <component
              :is="item.icon"
              :class="[
                'w-5 h-5 mr-3',
                isActive(item.path) ? 'text-white' : 'text-cod-grey',
              ]"
            />
            {{ item.name }}
          </router-link>
        </nav>

        <!-- Profil użytkownika -->
        <div class="p-3 border-t border-light-grey">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <div
                class="w-8 h-8 rounded-full bg-dynamic-orange flex items-center justify-center text-white font-semibold text-sm"
              >
                {{ userInitials }}
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-cod-grey">{{ userName }}</p>
                <p class="text-xs text-gray-500">{{ userEmail }}</p>
              </div>
            </div>
            <button
              @click="logout"
              class="p-2 text-gray-400 hover:text-dynamic-orange"
            >
              <component
                :is="icons.ArrowRightOnRectangleIcon"
                class="w-5 h-5"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useNavbar } from "./useNavbar";

export default defineComponent({
  name: "NavbarMobile",
  setup() {
    const {
      isMenuOpen,
      selectedVenue,
      menuItems,
      availableVenues,
      userName,
      userEmail,
      userInitials,
      icons,
      toggleMenu,
      closeMenu,
      logout,
      selectVenue,
      isActive,
    } = useNavbar();

    // Zmniejszona wysokość menu
    const menuHeight = "320px";

    return {
      isMenuOpen,
      selectedVenue,
      menuItems,
      availableVenues,
      userName,
      userEmail,
      userInitials,
      icons,
      toggleMenu,
      closeMenu,
      logout,
      selectVenue,
      isActive,
      menuHeight,
    };
  },
});
</script>
