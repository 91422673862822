export default {
  // Core mutations
  SET_VENUE(state, venue) {
    state.core.venue = venue;
  },
  SET_TAGS(state, tags) {
    state.core.tags = tags;
  },
  SET_TRAINERS(state, trainers) {
    state.core.trainers = trainers;
  },
  SET_SPORT_OBJECTS(state, sportObjects) {
    state.core.sportObjects = sportObjects;
  },
  SET_RESERVATIONS(state, reservations) {
    state.core.reservations = reservations;
  },
  SET_PRICE_LISTS(state, { key, priceLists }) {
    state.core.priceLists[key] = priceLists;
  },
  SET_LAST_REFRESH_TIME(state) {
    state.core.lastRefreshTime = new Date().toISOString();
  },

  // UI mutations
  SET_SELECTED_DATE(state, date) {
    state.ui.selectedDate = date;
  },
  SET_SELECTED_TAG(state, tag) {
    state.ui.selectedTag = tag;
  },
  SET_SHOW_CANCELLED_RESERVATIONS(state, show) {
    state.ui.showCancelledReservations = show;
  },
  SET_ACTIVE_SIDEBAR(state, sidebar) {
    state.ui.activeSidebar = sidebar;
    state.ui.isSidebarVisible = sidebar !== null;
  },
  SET_SELECTED_COURT(state, court) {
    state.ui.selectedCourt = court;
  },
  SET_SELECTED_TIME_RANGE(state, timeRange) {
    state.ui.selectedTimeRange = timeRange;
  },
  SET_SELECTED_RESERVATION_ID(state, id) {
    state.ui.selectedReservationId = id;
  },
  SET_SELECTED_EVENT_ID(state, id) {
    state.ui.selectedEventId = id;
  },
  SET_SIDEBAR_VISIBLE(state, visible) {
    state.ui.isSidebarVisible = visible;
  },
  SET_LOADING(state, isLoading) {
    state.ui.isLoading = isLoading;
  },
  SET_FORCE_REFRESH(state, force) {
    state.ui.forceRefresh = force;
  },

  // Error mutations
  SET_ERROR(state, { key, error }) {
    state.error[key] = error;
  },
};
