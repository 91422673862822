export default {
  isAuthenticated: (state) => !!state.authToken,
  currentUser: (state) => state.user,
  availableVenues: (state) => state.availableVenues,
  currentVenueId: (state) => state.currentVenueId,
  currentAppRoleId: (state) => state.currentAppRoleId,
  authToken: (state) => state.authToken,
  hasSelectedVenue: (state) => !!state.currentVenueId,
  message: (state) => state.message,
  error: (state) => state.error,
};
