import calendarCache from "@/utils/calendarCache";

export default {
  async updateSelectedDate({ commit, dispatch, state }, date) {
    if (state.ui.isLoading) return;

    const currentDate = state.ui.selectedDate;
    const newDate = new Date(date);

    // Sprawdź czy data faktycznie się zmieniła (porównaj tylko datę, bez czasu)
    if (
      currentDate.getFullYear() === newDate.getFullYear() &&
      currentDate.getMonth() === newDate.getMonth() &&
      currentDate.getDate() === newDate.getDate()
    ) {
      return;
    }

    commit("SET_SELECTED_DATE", date);
    await dispatch("fetchReservations");
  },

  updateSelectedTag({ commit }, tag) {
    commit("SET_SELECTED_TAG", tag);
  },

  async updateShowCancelledReservations({ commit, dispatch }, show) {
    // Usuń warunek sprawdzający poprzedni stan
    commit("SET_SHOW_CANCELLED_RESERVATIONS", show);
    // Wymuś odświeżenie cache'u rezerwacji
    calendarCache.invalidate("reservations");
    // Pobierz nowe rezerwacje
    await dispatch("fetchReservations");
  },

  setActiveSidebar({ commit }, sidebar) {
    commit("SET_ACTIVE_SIDEBAR", sidebar);
  },

  setSelectedCourt({ commit }, court) {
    commit("SET_SELECTED_COURT", court);
  },

  setSelectedTimeRange({ commit }, timeRange) {
    commit("SET_SELECTED_TIME_RANGE", timeRange);
  },

  setSelectedReservationId({ commit }, id) {
    commit("SET_SELECTED_RESERVATION_ID", id);
  },

  setSelectedEventId({ commit }, id) {
    commit("SET_SELECTED_EVENT_ID", id);
  },

  setSidebarVisible({ commit }, visible) {
    commit("SET_SIDEBAR_VISIBLE", visible);
  },

  // Cache management
  refreshCache({ dispatch }, key) {
    calendarCache.invalidate(key);
    switch (key) {
      case "venue":
        return dispatch("fetchVenue");
      case "tags":
        return dispatch("fetchTags");
      case "trainers":
        return dispatch("fetchTrainers");
      case "sportObjects":
        return dispatch("fetchSportObjects");
      case "reservations":
        return dispatch("fetchReservations");
      default:
        return Promise.resolve();
    }
  },
};
