export default [
  {
    path: "/clients",
    name: "Clients",
    component: () => import("../../views/clients/clients.vue"),
    meta: { requiresAuth: true, requiresVenue: true },
    children: [
      {
        path: "info/:id",
        name: "ClientInfo",
        component: () => import("../../views/clients/info.vue"),
        props: true,
      },
      {
        path: "reservations/:id",
        name: "ClientReservations",
        component: () => import("../../views/clients/reservations.vue"),
        props: true,
      },
      {
        path: "recurring-reservations/:id",
        name: "ClientRecurringReservations",
        component: () =>
          import("../../views/clients/recurringReservations.vue"),
        props: true,
      },
      {
        path: "carnets/:id",
        name: "ClientCarnets",
        component: () => import("../../views/clients/carnets.vue"),
        props: true,
      },
      {
        path: "trainer-profile/:id",
        name: "TrainerProfile",
        component: () => import("../../views/clients/trainerProfile.vue"),
        props: true,
      },
    ],
  },
];
