export default [
  {
    path: "/network-error",
    name: "NetworkError",
    component: () => import("../../views/errors/NetworkError.vue"),
    props: (route) => ({ errorDetails: route.query.error }),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../../views/errors/NotFound.vue"),
  },
];
