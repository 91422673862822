import { venueApi } from "@/api/modules/venue";
import { tagsApi } from "@/api/modules/tags";
import { trainersApi } from "@/api/modules/trainers";
import { sportObjectsApi } from "@/api/modules/sportObjects";
import calendarCache from "@/utils/calendarCache";
import logger from "../../../logger";

export default {
  async fetchVenue({ commit, state }) {
    if (state.core.venue) return state.core.venue;

    const cachedVenue = calendarCache.get("venue");
    if (cachedVenue) {
      commit("SET_VENUE", cachedVenue);
      return cachedVenue;
    }

    try {
      const response = await venueApi.getCurrentVenueData();
      commit("SET_VENUE", response.data);
      commit("SET_LAST_REFRESH_TIME");
      calendarCache.set("venue", response.data);
      commit("SET_ERROR", { key: "venue", error: null });
      return response.data;
    } catch (error) {
      logger.error("Error fetching venue:", error);
      commit("SET_ERROR", { key: "venue", error: error.message });
      throw error;
    }
  },

  async fetchTags({ commit, state }) {
    if (state.core.tags.length > 0) return state.core.tags;

    const cachedTags = calendarCache.get("tags");
    if (cachedTags) {
      commit("SET_TAGS", cachedTags);
      return cachedTags;
    }

    try {
      const response = await tagsApi.getAllVenueTags();
      commit("SET_TAGS", response.data);
      commit("SET_LAST_REFRESH_TIME");
      calendarCache.set("tags", response.data);
      commit("SET_ERROR", { key: "tags", error: null });
      return response.data;
    } catch (error) {
      logger.error("Error fetching tags:", error);
      commit("SET_ERROR", { key: "tags", error: error.message });
      throw error;
    }
  },

  async fetchTrainers({ commit, state }) {
    if (state.core.trainers.length > 0) return state.core.trainers;

    const cachedTrainers = calendarCache.get("trainers");
    if (cachedTrainers) {
      commit("SET_TRAINERS", cachedTrainers);
      return cachedTrainers;
    }

    try {
      const response = await trainersApi.getActiveTrainersFromVenue();
      const activeTrainers = response.data.filter(
        (trainer) => trainer.is_active && !trainer.is_deleted
      );
      commit("SET_TRAINERS", activeTrainers);
      commit("SET_LAST_REFRESH_TIME");
      calendarCache.set("trainers", activeTrainers);
      commit("SET_ERROR", { key: "trainers", error: null });
      return activeTrainers;
    } catch (error) {
      logger.error("Error fetching trainers:", error);
      commit("SET_ERROR", { key: "trainers", error: error.message });
      throw error;
    }
  },

  async fetchSportObjects({ commit, state }) {
    if (state.core.sportObjects.length > 0) return state.core.sportObjects;

    const cachedSportObjects = calendarCache.get("sportObjects");
    if (cachedSportObjects) {
      commit("SET_SPORT_OBJECTS", cachedSportObjects);
      return cachedSportObjects;
    }

    try {
      const response = await sportObjectsApi.getAllSportObjects();
      commit("SET_SPORT_OBJECTS", response.data);
      commit("SET_LAST_REFRESH_TIME");
      calendarCache.set("sportObjects", response.data);
      commit("SET_ERROR", { key: "sportObjects", error: null });
      return response.data;
    } catch (error) {
      logger.error("Error fetching sport objects:", error);
      commit("SET_ERROR", { key: "sportObjects", error: error.message });
      throw error;
    }
  },

  async fetchReservations({ commit, state }) {
    if (state.ui.isLoading) return state.core.reservations;

    const date = state.ui.selectedDate;
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    // Sprawdź cache tylko jeśli nie ma wymuszonego odświeżenia
    const cacheKey = `reservations_${startOfDay.toISOString()}`;
    const cachedReservations = calendarCache.get(cacheKey);

    if (cachedReservations && !state.ui.forceRefresh) {
      commit("SET_RESERVATIONS", cachedReservations);
      return cachedReservations;
    }

    commit("SET_LOADING", true);
    try {
      const [reservationsResponse, groupClassesResponse] = await Promise.all([
        venueApi.getReservations({
          start_time: startOfDay.toISOString(),
          end_time: endOfDay.toISOString(),
        }),
        venueApi.getGroupClasses({
          start_time: startOfDay.toISOString(),
          end_time: endOfDay.toISOString(),
        }),
      ]);

      const filteredReservations = state.ui.showCancelledReservations
        ? reservationsResponse.data
        : reservationsResponse.data.filter(
            (res) =>
              res.cancellation_status !== "cancelled" &&
              res.cancellation_status !== "cancelled_not_on_time"
          );

      const filteredGroupClasses = state.ui.showCancelledReservations
        ? groupClassesResponse.data.filter((groupClass) => !groupClass.deleted)
        : groupClassesResponse.data.filter(
            (groupClass) => !groupClass.deleted && !groupClass.is_cancelled
          );

      const allReservations = [
        ...filteredReservations.map((res) => ({ ...res, isGroupClass: false })),
        ...filteredGroupClasses.map((groupClass) => ({
          ...groupClass,
          type: "class",
          isGroupClass: true,
        })),
      ];

      commit("SET_RESERVATIONS", allReservations);
      commit("SET_LAST_REFRESH_TIME");
      calendarCache.set(cacheKey, allReservations);
      commit("SET_ERROR", { key: "reservations", error: null });
      return allReservations;
    } catch (error) {
      logger.error("Error fetching reservations:", error);
      commit("SET_ERROR", { key: "reservations", error: error.message });
      throw error;
    } finally {
      commit("SET_LOADING", false);
      if (state.ui.forceRefresh) {
        commit("SET_FORCE_REFRESH", false);
      }
    }
  },

  forceRefresh({ commit }) {
    commit("SET_FORCE_REFRESH", true);
    commit("SET_LAST_REFRESH_TIME");
  },
};
