import state from "./state";
import mutations from "./mutations";
import getters from "./getters";
import coreActions from "./actions/core";
import uiActions from "./actions/ui";

// Łączymy wszystkie akcje
const actions = {
  ...coreActions,
  ...uiActions,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
