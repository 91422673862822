import state from "./state";
import mutations from "./mutations";
import getters from "./getters";
import authActions from "./actions/auth";
import userActions from "./actions/user";

// Łączymy wszystkie akcje
const actions = {
  ...authActions,
  ...userActions,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
