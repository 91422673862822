import store from "@/store";
import router from "@/router";
import { getCurrentVenueId } from "../apiConfig";

const isDevelopment = () => process.env.NODE_ENV === "development";

export const createRequestInterceptor =
  (requiresAuth = false) =>
  (config) => {
    if (requiresAuth) {
      const token = store.getters["employees/authToken"];
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      } else {
        const localStorageToken = localStorage.getItem("authToken");
        if (localStorageToken) {
          config.headers["Authorization"] = `Bearer ${localStorageToken}`;
        }
      }
    }

    const currentVenueId = getCurrentVenueId();

    if (currentVenueId && config.url !== "employee/set_venue") {
      const venueIdNumber = parseInt(currentVenueId, 10);
      if (!isNaN(venueIdNumber)) {
        if (config.method.toLowerCase() === "get") {
          config.params = { ...config.params, venue_id: venueIdNumber };
        } else {
          config.data = { ...config.data, venue_id: venueIdNumber };
        }
      } else if (isDevelopment()) {
        console.error("Invalid venue_id:", currentVenueId);
      }
    }

    if (isDevelopment()) {
      console.info(
        `API Call: ${config.method.toUpperCase()} ${config.url}`,
        config.params,
        config.data,
        config.headers
      );
    }
    return config;
  };

export const createResponseInterceptor = (isPublic = false) => ({
  success: (response) => {
    if (isDevelopment()) {
      console.info(
        `${isPublic ? "PUBLIC " : ""}API Response: ${response.status} ${
          response.statusText
        }`,
        response.data
      );
    }
    return response;
  },
  error: (error) => {
    if (isDevelopment()) {
      console.error(
        `${isPublic ? "PUBLIC " : ""}API Response Error:`,
        error.response ? error.response.data : error.message
      );
    }

    // Obsługa błędów sieciowych
    if (!error.response || error.message === "Network Error") {
      const errorDetails =
        error.message === "Network Error"
          ? "Brak połączenia z serwerem"
          : error.message;

      // Sprawdzamy czy nie jesteśmy już na stronie błędu, aby uniknąć zapętlenia
      if (router.currentRoute.value.name !== "NetworkError") {
        router.push({
          name: "NetworkError",
          query: { error: errorDetails },
        });
      }
      return Promise.reject(error);
    }

    // Obsługa błędu autoryzacji
    if (error.response && error.response.status === 401) {
      if (isDevelopment()) {
        console.log("Token expired, logging out...");
      }
      store.dispatch("employees/handleAuthError");
    }

    return Promise.reject(error);
  },
});
