class CalendarCache {
  constructor() {
    this.cache = new Map();
    this.config = {
      // Dane statyczne (rzadko zmieniane)
      staticTTL: 24 * 60 * 60 * 1000, // 24 godziny
      // Dane dynamiczne (często zmieniane)
      dynamicTTL: 5 * 60 * 1000, // 5 minut
      // Rezerwacje (bardzo często zmieniane)
      reservationsTTL: 1 * 60 * 1000, // 1 minuta
    };

    // Konfiguracja TTL dla różnych typów danych
    this.ttlConfig = {
      venue: this.config.staticTTL,
      tags: this.config.staticTTL,
      sportObjects: this.config.staticTTL,
      trainers: this.config.dynamicTTL,
      reservations: this.config.reservationsTTL,
      priceLists: this.config.dynamicTTL,
    };

    // Inicjalizacja cache z localStorage tylko jeśli dane są aktualne
    this._initializeFromLocalStorage();

    // Okresowe czyszczenie wygasłych danych
    setInterval(() => this._cleanExpiredEntries(), 60 * 1000); // Co minutę
  }

  /**
   * Zapisuje dane w cache
   * @param {string} key - Klucz cache
   * @param {any} data - Dane do zapisania
   * @param {number} [ttl] - Opcjonalny TTL (Time To Live) w milisekundach
   */
  set(key, data, ttl) {
    const timestamp = Date.now();
    const defaultTTL = this.ttlConfig[key] || this.config.dynamicTTL;

    const entry = {
      data,
      timestamp,
      ttl: ttl || defaultTTL,
      expiresAt: timestamp + (ttl || defaultTTL),
    };

    this.cache.set(key, entry);
    this._persistToLocalStorage();
  }

  /**
   * Pobiera dane z cache
   * @param {string} key - Klucz cache
   * @returns {any|null} Dane z cache lub null jeśli nie znaleziono/wygasły
   */
  get(key) {
    const entry = this.cache.get(key);

    if (!entry) {
      // Spróbuj pobrać z localStorage tylko jeśli nie ma w pamięci
      const persistedData = this._getFromLocalStorage(key);
      if (persistedData && this._isEntryValid(persistedData)) {
        this.cache.set(key, persistedData);
        return persistedData.data;
      }
      return null;
    }

    if (!this._isEntryValid(entry)) {
      this.invalidate(key);
      return null;
    }

    return entry.data;
  }

  /**
   * Sprawdza czy dane w cache są aktualne
   * @param {string} key - Klucz cache
   * @returns {boolean}
   */
  isValid(key) {
    const entry = this.cache.get(key);
    if (!entry) return false;
    return this._isEntryValid(entry);
  }

  /**
   * Sprawdza czy wpis jest aktualny
   * @private
   */
  _isEntryValid(entry) {
    return Date.now() < entry.expiresAt;
  }

  /**
   * Usuwa dane z cache
   * @param {string} key - Klucz cache
   */
  invalidate(key) {
    this.cache.delete(key);
    this._persistToLocalStorage();
  }

  /**
   * Czyści cały cache
   */
  clear() {
    this.cache.clear();
    localStorage.removeItem("calendarCache");
  }

  /**
   * Czyści wygasłe wpisy
   * @private
   */
  _cleanExpiredEntries() {
    const now = Date.now();
    let hasExpired = false;

    for (const [key, entry] of this.cache.entries()) {
      if (now >= entry.expiresAt) {
        this.cache.delete(key);
        hasExpired = true;
      }
    }

    if (hasExpired) {
      this._persistToLocalStorage();
    }
  }

  /**
   * Zapisuje cache w localStorage
   * @private
   */
  _persistToLocalStorage() {
    try {
      const data = {};
      this.cache.forEach((value, key) => {
        // Zapisuj tylko aktualne dane
        if (this._isEntryValid(value)) {
          data[key] = value;
        }
      });
      localStorage.setItem("calendarCache", JSON.stringify(data));
    } catch (error) {
      console.error("Error persisting cache to localStorage:", error);
    }
  }

  /**
   * Pobiera dane z localStorage
   * @private
   */
  _getFromLocalStorage(key) {
    try {
      const data = localStorage.getItem("calendarCache");
      if (!data) return null;

      const parsed = JSON.parse(data);
      const entry = parsed[key];

      // Zwróć tylko jeśli dane są aktualne
      return entry && this._isEntryValid(entry) ? entry : null;
    } catch (error) {
      console.error("Error reading cache from localStorage:", error);
      return null;
    }
  }

  /**
   * Inicjalizuje cache danymi z localStorage
   * @private
   */
  _initializeFromLocalStorage() {
    try {
      const data = localStorage.getItem("calendarCache");
      if (!data) return;

      const parsed = JSON.parse(data);
      const now = Date.now();

      Object.entries(parsed).forEach(([key, value]) => {
        // Przywróć tylko aktualne dane
        if (value && value.expiresAt && value.expiresAt > now) {
          this.cache.set(key, value);
        }
      });
    } catch (error) {
      console.error("Error initializing cache from localStorage:", error);
      // W przypadku błędu, wyczyść cache
      this.clear();
    }
  }
}

// Singleton instance
const calendarCache = new CalendarCache();
export default calendarCache;
