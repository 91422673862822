export default {
  core: {
    venue: null,
    tags: [],
    trainers: [],
    sportObjects: [],
    reservations: [],
    priceLists: {}, // Cache dla cenników, klucz: `${beginDate}_${endDate}_${sportObjectId}`
    lastRefreshTime: null, // Czas ostatniego odświeżenia
  },
  ui: {
    selectedDate: new Date(),
    selectedTag: null,
    showCancelledReservations: false,
    activeSidebar: null,
    selectedCourt: null,
    selectedTimeRange: null,
    selectedReservationId: null,
    selectedEventId: null,
    isSidebarVisible: false,
    isLoading: false,
    forceRefresh: false, // Flaga wymuszająca odświeżenie danych
  },
  error: {
    venue: null,
    tags: null,
    trainers: null,
    sportObjects: null,
    reservations: null,
    priceLists: null,
  },
};
