import store from "@/store";

const BASE_URL =
  import.meta.env.VITE_APP_API_BASE_URL || "https://zephyr.playmore.pl";
const EMPLOYEES_ENDPOINT =
  import.meta.env.VITE_APP_API_EMPLOYEES_ENDPOINT || "api:BDV715wJ/";
const PUBLIC_ENDPOINT =
  import.meta.env.VITE_APP_API_PUBLIC_ENDPOINT || "api:4Tf8JTHd/";

export const API_CONFIG = {
  EMPLOYEES: {
    baseURL: `${BASE_URL}/${EMPLOYEES_ENDPOINT}`,
    requiresAuth: true,
  },
  PUBLIC: {
    baseURL: `${BASE_URL}/${PUBLIC_ENDPOINT}`,
    requiresAuth: false,
  },
};

export const getCurrentVenueId = () => {
  const venueId = store.getters["employees/currentVenueId"];
  return venueId;
};
