export default [
  {
    path: "/",
    name: "Home",
    component: () => import("../../views/calendar/Calendar.vue"),
    meta: { requiresAuth: true, requiresVenue: true },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import("../../views/calendar/Calendar.vue"),
    meta: { requiresAuth: true, requiresVenue: true },
  },
];
