/**
 * Logger utility for conditional logging based on environment.
 * Logs are only displayed in development environment.
 *
 * @example
 * import logger from '../logger';
 *
 * logger.log('Debug message');
 * logger.info('Info message');
 * logger.error('Error message');
 * logger.warn('Warning message');
 */

const isDevelopment = () => process.env.NODE_ENV === "development";

export const logger = {
  /**
   * Log debug message (only in development)
   * @param {...any} args - Arguments to log
   */
  log: (...args) => {
    if (isDevelopment()) {
      console.log(...args);
    }
  },

  /**
   * Log info message (only in development)
   * @param {...any} args - Arguments to log
   */
  info: (...args) => {
    if (isDevelopment()) {
      console.info(...args);
    }
  },

  /**
   * Log error message (only in development)
   * @param {...any} args - Arguments to log
   */
  error: (...args) => {
    if (isDevelopment()) {
      console.error(...args);
    }
  },

  /**
   * Log warning message (only in development)
   * @param {...any} args - Arguments to log
   */
  warn: (...args) => {
    if (isDevelopment()) {
      console.warn(...args);
    }
  },
};

export default logger;
