import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/main.css";

const app = createApp(App);

// Initialize Sentry with simplified configuration
Sentry.init({
  app,
  dsn: "https://be00774052ebb1c048ecb0b7367bcbed@o4508270333788160.ingest.de.sentry.io/4508273616355408",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  // Podstawowa konfiguracja śledzenia błędów
  beforeSend(event) {
    const state = store?.state;

    // Dodaj podstawowy kontekst
    event.extra = {
      authStatus: state?.employees?.authToken ? "present" : "missing",
      venueId: state?.employees?.currentVenue?.venueId,
      route: router.currentRoute.value.path,
    };

    // Dodaj informacje o użytkowniku jeśli są dostępne
    const user = state?.employees?.user;
    if (user) {
      event.user = {
        id: user.id,
        email: user.email,
        venueId: state?.employees?.currentVenue?.venueId,
      };
    }

    return event;
  },

  // Uproszczona konfiguracja breadcrumbs
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === "xhr") {
      breadcrumb.data = {
        ...breadcrumb.data,
        timestamp: new Date().toISOString(),
      };
    }
    return breadcrumb;
  },

  // Podstawowa konfiguracja tracingu
  tracesSampleRate: 0.2, // Zmniejszone z 1.0 do 0.2 (20% ruchu)
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/zephyr\.playmore\.pl\/api/,
  ],

  // Zmniejszone sample rates dla Session Replay
  replaysSessionSampleRate: 0.05, // Zmniejszone z 0.1 do 0.05 (5% sesji)
  replaysOnErrorSampleRate: 0.5, // Zmniejszone z 1.0 do 0.5 (50% sesji z błędami)

  // Podstawowa konfiguracja
  environment: process.env.NODE_ENV,
  debug: false,
});

// Prosty handler błędów
app.config.errorHandler = (error, instance, info) => {
  Sentry.captureException(error);
};

app.use(store);
app.use(router);

// Initialize dark mode
const isDarkMode = localStorage.getItem("darkMode") === "true";
if (isDarkMode) {
  document.documentElement.classList.add("dark");
}

// Uproszczona inicjalizacja
store
  .dispatch("employees/checkAuth")
  .then(() => {
    app.mount("#app");
  })
  .catch(() => {
    app.mount("#app");
  });
