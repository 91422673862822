import logger from "../../logger";

export default {
  SET_USER(state, user) {
    logger.info("Mutating SET_USER:", user);
    state.user = user;
    state.isAuthenticated = !!state.authToken;
  },

  SET_AUTH_TOKEN(state, token) {
    logger.info("Mutating SET_AUTH_TOKEN:", token);
    state.authToken = token;
    if (token) {
      localStorage.setItem("authToken", token);
    } else {
      localStorage.removeItem("authToken");
    }
  },

  SET_AVAILABLE_VENUES(state, venues) {
    logger.info("Mutating SET_AVAILABLE_VENUES:", venues);
    state.availableVenues = venues;
  },

  SET_CURRENT_VENUE(state, { venueId, appRoleId }) {
    logger.info("Mutating SET_CURRENT_VENUE:", venueId, appRoleId);
    state.currentVenueId = venueId;
    state.currentAppRoleId = appRoleId;
    if (venueId) {
      localStorage.setItem("currentVenueId", venueId);
    } else {
      localStorage.removeItem("currentVenueId");
    }
  },

  SET_MESSAGE(state, message) {
    logger.info("Mutating SET_MESSAGE:", message);
    state.message = message;
  },

  SET_ERROR(state, error) {
    logger.info("Mutating SET_ERROR:", error);
    state.error = error;
  },

  CLEAR_MESSAGE(state) {
    logger.info("Mutating CLEAR_MESSAGE");
    state.message = null;
  },

  CLEAR_ERROR(state) {
    logger.info("Mutating CLEAR_ERROR");
    state.error = null;
  },
};
