<template>
  <div class="h-full">
    <!-- Mobile Navbar (widoczny tylko na małych ekranach) -->
    <NavbarMobile class="block md:hidden" />

    <!-- Desktop Navbar (widoczny tylko na większych ekranach) -->
    <NavbarDesktop class="hidden md:block" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import NavbarMobile from "./NavbarMobile.vue";
import NavbarDesktop from "./NavbarDesktop.vue";

export default defineComponent({
  name: "Navbar",
  components: {
    NavbarMobile,
    NavbarDesktop,
  },
});
</script>
