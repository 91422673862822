export default [
  {
    path: "/login",
    name: "EmployeeLogin",
    component: () => import("../../views/login/LoginView.vue"),
    props: (route) => ({ magicToken: route.query.magicToken }),
  },
  {
    path: "/logout",
    name: "EmployeeLogout",
    component: () => import("../../views/login/LogoutView.vue"),
  },
];
