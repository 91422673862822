export default [
  {
    path: "/reports",
    name: "Reports",
    component: () => import("../../views/reports/home.vue"),
    meta: { requiresAuth: true, requiresVenue: true },
    children: [
      {
        path: "new-users",
        name: "NewUsersReport",
        component: () =>
          import("../../views/reports/new-users/NewUsersReport.vue"),
      },
      {
        path: "payments",
        name: "PaymentsReport",
        component: () =>
          import("../../views/reports/payments/PaymentsReport.vue"),
      },
      {
        path: "reservations",
        name: "ReservationsReport",
        component: () =>
          import("../../views/reports/reservations/ReservationsReport.vue"),
      },
      {
        path: "reception",
        name: "ReceptionReport",
        component: () =>
          import("../../views/reports/reception/ReceptionReport.vue"),
      },
      {
        path: "notifications",
        name: "NotificationsReport",
        component: () =>
          import("../../views/reports/notifications/NotificationsReport.vue"),
      },
      {
        path: "carnets",
        name: "CarnetsReport",
        component: () =>
          import("../../views/reports/carnets/CarnetsReport.vue"),
      },
      {
        path: "classes",
        name: "ClassesReport",
        component: () =>
          import("../../views/reports/classes/ClassesReport.vue"),
      },
      {
        path: "courts",
        name: "CourtsReport",
        component: () => import("../../views/reports/courts/CourtsReport.vue"),
      },
    ],
  },
];
