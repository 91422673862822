<template>
  <!-- Sidebar -->
  <div
    class="bg-white border-r border-light-grey transition-all duration-300 h-full"
    :class="{ 'w-20': isCollapsed, 'w-64': !isCollapsed }"
  >
    <div class="h-full flex flex-col">
      <!-- Logo i przycisk zwijania -->
      <div
        class="px-4 py-5 flex items-center"
        :class="{
          'justify-between': !isCollapsed,
          'justify-center': isCollapsed,
        }"
      >
        <img
          v-if="!isCollapsed"
          src="/images/Playmore-Logo-Default-version-SVG.svg"
          alt="Playmore Logo"
          class="h-8"
        />
        <button
          @click="toggleSidebar"
          class="text-cod-grey hover:text-dynamic-orange focus:outline-none"
        >
          <component :is="icons.Bars3Icon" class="w-6 h-6" />
        </button>
      </div>

      <!-- Wybór ośrodka -->
      <div v-if="!isCollapsed" class="px-4 py-2">
        <select
          v-model="selectedVenue"
          @change="selectVenue"
          class="w-full p-2 text-sm border border-light-grey rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-dynamic-orange focus:border-dynamic-orange"
        >
          <option value="" disabled>Wybierz ośrodek</option>
          <option
            v-for="venue in availableVenues"
            :key="venue.id"
            :value="venue.id"
          >
            {{ venue.name }}
          </option>
        </select>
      </div>

      <!-- Menu nawigacji -->
      <nav class="flex-1 px-2 py-4 overflow-y-auto">
        <router-link
          v-for="(item, index) in menuItems"
          :key="index"
          :to="item.path"
          class="group flex items-center px-2 py-2 mb-1 text-base font-medium rounded-md transition-colors duration-150"
          :class="[
            isActive(item.path)
              ? 'bg-dynamic-orange text-white'
              : 'text-cod-grey hover:text-dynamic-orange hover:bg-gray-50',
            { 'justify-center': isCollapsed },
          ]"
        >
          <component
            :is="item.icon"
            :class="[
              'w-6 h-6',
              { 'mr-4': !isCollapsed },
              isActive(item.path)
                ? 'text-white'
                : 'text-cod-grey group-hover:text-dynamic-orange',
            ]"
          />
          <span v-if="!isCollapsed">{{ item.name }}</span>
        </router-link>
      </nav>

      <!-- Profil użytkownika -->
      <div class="border-t border-light-grey p-4 mt-auto">
        <div
          :class="{
            'flex items-center justify-between': !isCollapsed,
            'flex flex-col items-center': isCollapsed,
          }"
        >
          <div class="flex items-center">
            <div
              class="w-10 h-10 rounded-full bg-dynamic-orange flex items-center justify-center text-white font-semibold"
            >
              {{ userInitials }}
            </div>
            <div v-if="!isCollapsed" class="ml-3">
              <p class="text-sm font-medium text-cod-grey">{{ userName }}</p>
              <p class="text-xs text-gray-500">{{ userEmail }}</p>
            </div>
          </div>
          <button
            @click="logout"
            class="text-gray-400 hover:text-dynamic-orange transition-colors duration-150"
            :class="{ 'mt-2': isCollapsed }"
          >
            <component :is="icons.ArrowRightOnRectangleIcon" class="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useNavbar } from "./useNavbar";

export default defineComponent({
  name: "NavbarDesktop",
  setup() {
    const {
      isCollapsed,
      selectedVenue,
      menuItems,
      availableVenues,
      userName,
      userEmail,
      userInitials,
      icons,
      toggleSidebar,
      logout,
      selectVenue,
      isActive,
    } = useNavbar();

    return {
      isCollapsed,
      selectedVenue,
      menuItems,
      availableVenues,
      userName,
      userEmail,
      userInitials,
      icons,
      toggleSidebar,
      logout,
      selectVenue,
      isActive,
    };
  },
});
</script>
