import { carnetsApi } from "@/api/modules/carnets";
import { tagsApi } from "@/api/modules/tags";

const state = {
  carnetTypes: [],
  tags: [],
  isCarnetTypesLoaded: false,
  isTagsLoaded: false,
  loading: false,
};

const mutations = {
  SET_CARNET_TYPES(state, carnetTypes) {
    state.carnetTypes = carnetTypes;
    state.isCarnetTypesLoaded = true;
  },
  SET_TAGS(state, tags) {
    state.tags = tags;
    state.isTagsLoaded = true;
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
  RESET_CARNETS_STATE(state) {
    state.carnetTypes = [];
    state.isCarnetTypesLoaded = false;
  },
  RESET_ALL_STATE(state) {
    state.carnetTypes = [];
    state.tags = [];
    state.isCarnetTypesLoaded = false;
    state.isTagsLoaded = false;
  },
};

const actions = {
  async fetchCarnetTypes({ commit, state }) {
    if (state.isCarnetTypesLoaded) {
      return state.carnetTypes;
    }

    commit("SET_LOADING", true);
    try {
      const response = await carnetsApi.getAllCarnetTypes();
      commit("SET_CARNET_TYPES", response.data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async fetchTags({ commit, state }) {
    if (state.isTagsLoaded) {
      return state.tags;
    }

    try {
      const response = await tagsApi.getAllVenueTags();
      commit("SET_TAGS", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async addCarnetType({ commit, dispatch }, carnetData) {
    try {
      const response = await carnetsApi.addCarnetTypeToVenue(carnetData);
      commit("RESET_CARNETS_STATE"); // Reset stanu przed ponownym pobraniem
      await dispatch("fetchCarnetTypes");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateCarnetType({ commit, dispatch }, { id, data }) {
    try {
      const response = await carnetsApi.editCarnetType(id, data);
      commit("RESET_CARNETS_STATE"); // Reset stanu przed ponownym pobraniem
      await dispatch("fetchCarnetTypes");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async deleteCarnetType({ commit, dispatch }, id) {
    try {
      await carnetsApi.deleteCarnetType(id);
      commit("RESET_CARNETS_STATE"); // Reset stanu przed ponownym pobraniem
      await dispatch("fetchCarnetTypes");
    } catch (error) {
      throw error;
    }
  },

  resetCarnetsState({ commit }) {
    commit("RESET_CARNETS_STATE");
  },

  resetAllState({ commit }) {
    commit("RESET_ALL_STATE");
  },
};

const getters = {
  getCarnetTypes: (state) => state.carnetTypes,
  getTags: (state) => state.tags,
  isLoading: (state) => state.loading,
  getTagById: (state) => (id) => state.tags.find((tag) => tag.id === id),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
