const state = {
  isLoading: false,
};

const mutations = {
  SET_LOADING(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  setLoading({ commit }, value) {
    commit("SET_LOADING", value);
  },

  clearLocalStorageExceptUser() {
    // Zachowaj dane logowania
    const authToken = localStorage.getItem("authToken");
    const currentVenueId = localStorage.getItem("currentVenueId");

    // Wyczyść cały localStorage
    localStorage.clear();

    // Przywróć dane logowania
    if (authToken) {
      localStorage.setItem("authToken", authToken);
    }
    if (currentVenueId) {
      localStorage.setItem("currentVenueId", currentVenueId);
    }
  },
};

const getters = {
  isLoading: (state) => state.isLoading,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
