import calendarCache from "@/utils/calendarCache";

export default {
  isLoading: (state) => state.ui.isLoading,

  hasError: (state) =>
    Object.values(state.error).some((error) => error !== null),

  getError: (state) => (key) => state.error[key],

  filteredSportObjects: (state) => {
    if (!state.ui.selectedTag) return state.core.sportObjects;
    return state.core.sportObjects.filter(
      (obj) => obj.tag_id === state.ui.selectedTag.id
    );
  },

  getPriceLists: (state) => (key) => state.core.priceLists[key] || [],

  isCacheValid: () => (key) => calendarCache.isValid(key),

  getCachedData: () => (key) => calendarCache.get(key),

  isSidebarVisible: (state) => state.ui.isSidebarVisible,
};
