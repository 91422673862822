import { api } from "@/api/api";
import calendarCache from "@/utils/calendarCache";
import logger from "../../../logger";

export default {
  async fetchUserDetails({ commit, dispatch }) {
    logger.log("Action fetchUserDetails");
    try {
      const response = await api.get(`auth/me`);
      const userDetails = response.data;
      logger.info("Fetched userDetails:", userDetails);
      commit("SET_USER", userDetails);
      if (userDetails._employee_venue_profiles) {
        const venues = userDetails._employee_venue_profiles.map((profile) => ({
          id: profile.venue_id,
          roleId: profile.app_role_id,
          name: profile.name || `Venue ${profile.venue_id}`,
        }));
        logger.info("Mapped available venues:", venues);
        commit("SET_AVAILABLE_VENUES", venues);
      }
      return userDetails;
    } catch (error) {
      logger.error("Fetching user details failed:", error);
      if (error.response && error.response.status === 401) {
        await dispatch("handleAuthError");
      } else {
        commit("SET_ERROR", "Unable to fetch user details.");
      }
    }
  },

  async setEmployeeVenue({ commit, dispatch }, venueId) {
    logger.log("Action setEmployeeVenue with venueId:", venueId);
    try {
      const response = await api.patch(`employee/set_venue`, {
        venue_id: venueId,
      });
      const { current_venue_id, current_app_role_id } = response.data;
      logger.info(
        "Set employee venue to:",
        current_venue_id,
        current_app_role_id
      );

      // Czyszczenie cache przed zmianą venue
      calendarCache.clear();

      commit("SET_CURRENT_VENUE", {
        venueId: current_venue_id,
        appRoleId: current_app_role_id,
      });
      return response.data;
    } catch (error) {
      logger.error("Setting employee venue failed:", error);
      if (error.response && error.response.status === 401) {
        await dispatch("handleAuthError");
      } else {
        commit("SET_ERROR", "Failed to set venue.");
      }
    }
  },

  checkAuth({ state, dispatch }) {
    logger.log("Checking auth status");
    if (state.authToken) {
      return dispatch("fetchUserDetails");
    }
  },

  clearMessage({ commit }) {
    logger.info("Clearing message");
    commit("CLEAR_MESSAGE");
  },

  clearError({ commit }) {
    logger.info("Clearing error");
    commit("CLEAR_ERROR");
  },
};
